import React from 'react';
import { Link } from 'react-router-dom';

function Navbar({toggle, setIsOpen}) {
    return (
        <nav
             className="flex justify-between items-center h-16 bg-white text-black relative shadow-sm font-mono"
             role="navigation"
        >
            <Link to="/" className="pl-8" onClick={() => setIsOpen(false)}>
                <img src="/logo256.png" alt="logo" className="h-10 w-10 mr-5 inline-block" />
                MatterHive Software
            </Link>
            <div className="px-4 cursor-pointer md:hidden" onClick={toggle}>
                <svg className="w-8 h-8" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path clip-rule="evenodd" d="M2 4.75A.75.75 0 0 1 2.75 4h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 4.75ZM2 10a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 10Zm0 5.25a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z" fill-rule="evenodd"></path>
                </svg>
            </div>
            <div className="pr-8 md:block hidden">
                <Link to="/" className="p-4">
                    Home
                </Link>
                <Link to="/services" className="p-4">
                    Services
                </Link>
                <Link to="/about" className="p-4">
                    About
                </Link>
                <Link to="/contact" className="p-4">
                    Contact
                </Link>
            </div>
        </nav>
    );
}

export default Navbar;
