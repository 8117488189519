import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import ServiceBadge from '../components/ServiceBadge';
import ServiceModal from '../components/ServiceModal';

function Services() {

  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const openModal = (content) => {
     setModalContent(content);
     setIsOpen(true);
  };

  const closeModal = () => {
     setIsOpen(false);
  };

  const services = [
      {
          title: "Custom Web Software Development",
          description: `We can help you with your custom web software development needs.

MatterHive Software has years of experience in building web applications using modern web technologies. We can help you build your web application from scratch, or we can help you maintain and enhance your existing web application. We can help you with your front-end development, back-end development, and full-stack development needs.

Common languages and frameworks we have experience with include Javascript (React), Python (Django/Flask), Perl (Mojo) and PHP (Laravel). We can also work with custom applications in these languages that don't use a framework.

We can also help you with your database needs, including SQL databases like MySQL and PostgreSQL, and NoSQL databases like MongoDB and DynamoDB.`

      },
      {
          title: "PropTech/FinTech Product Development and Consulting",
          description: `MatterHive Software has years of experience in conceptualising, building, launching and scaling PropTech and FinTech products.

We can help you build your PropTech or FinTech product, or provide consulting services to help you grow your business.`
      },
      {
          title: "Solution Design & Architecture",
          description: `We can help you design and architect your software solution.

To allow a system to scale to tens of thousands of daily users, it is critical that the system is designed and architected correctly from the start. We can help you with your software architecture, data architecture, and system architecture needs.`
      },
      {
          title: "Cloud Engineering",
          description: `MatterHive Software has years of experience in cloud engineering, in particular using the AWS platform.

We can ensure that your cloud environment is secure, scalable, resilient and cost-effective. We can help you with your cloud migration, cloud cost optimization, and cloud engineering needs.

Contact us today to find out how we can help you with your cloud engineering needs.`
      },
      {
          title: "Cloud Cost Optimization",
          description: `MatterHive Software has experience in identifying and optimising cloud cost issues. We can help you reduce your cloud costs by identifying cost savings opportunities and implementing cost optimization strategies.

Ensure your tech budget is spent on improving your product, not on unnecessary cloud costs. Contact us today to find out how we can help you with your cloud cost optimization needs.`
      },
      {
          title: "Integrations",
          description: `MatterHive Software has vast experience in integrating systems using APIs.

If your business or product uses popular third-party services, such as payment gateways, CRMs, accounting or marketing automation tools, we can help you integrate these services with your software systems.

We can help you integrate your software systems with third-party APIs, or we can help you build custom APIs for your software systems. We can also help you with your data integration and processing needs.`
      },
  ];

  return (
    <>

        <link rel="canonical" href="https://www.matterhive.com.au/services" />

        <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-slate-900 to-slate-600 text-white">

          <PageTitle title="Services" />

          <p className="text-center mx-20 pt-20 text-lg md:text-2xl">Find out how MatterHive Software can help your business today. Click on the tiles below to learn more about the specialised services we can offer.</p>

          <div className="grid md:grid-cols-3 w-4/5 gap-4 mt-20 mx-20">

              { services.map((service, index) => (
                  <ServiceBadge key={index} title={service.title} description={service.description} openModal={openModal} />
              )) }

          </div>

          <ServiceModal isOpen={isOpen} onClose={closeModal}>{modalContent}</ServiceModal>

          <p className="text-left md:text-justify mx-20 pt-20 text-lg md:text-xl">
              MatterHive Software is well versed in a variety of technologies and platforms. We can help you with your cloud migration, cloud cost optimization, and cloud engineering needs. We can also help you with your custom software development, PropTech/FinTech product development and consulting, solution design and architecture, and requirements analysis. <Link to="/contact" className="text-blue-300 hover:text-blue-200">Contact us</Link> today to learn more about how we can help your business.
          </p>

          <img src="/images/business1.jpg" alt="Business Software" className="w-1/2 my-20 rounded-lg" />

        </div>

    </>
  );


}

export default Services;
