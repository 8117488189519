import React from 'react';
import { Link } from 'react-router-dom';

function Hero() {
    return (
        <div className="bg-white h-screen flex flex-col justify-center items-center bg-hero bg-no-repeat bg-cover bg-center bg-fixed"
             style={{ backgroundImage: "url(/images/background-1.jpg)" }}
        >
            <div className="bg-slate-100 p-1 md:p-5 rounded-md shadow-md">

                <div className="flex items-center w-full h-64 p-4">

                    <div className="w-1/4 aspect-square flex items-center justify-center">
                        <img src="/logo256.png" alt="logo" className="h-auto" />
                    </div>

                    <div className="w-3/4 pl-4">
                        <h1 className="text-3xl md:text-7xl text-slate-700">MatterHive Software</h1>
                        <p className="text-xl md:text-3xl text-slate-700">Custom Software and Consulting</p>
                    </div>

                </div>

            </div>
            <div className="grid grid-cols-2 my-5 gap-8">
                <Link
                    className='py-6 px-10 bg-black text-white rounded-lg text-3xl border-2 border-white hover:border-transparent hover:bg-slate-300 hover:text-black flex items-center justify-center'
                    to='/services'
                >
                    Find out more
                </Link>
                <Link
                    className='py-6 px-10 bg-gray-300 text-slate-900 border-white border-2 rounded-lg text-3xl hover:border-transparent hover:bg-gray-200 flex text-center justify-center items-center'
                    to='/contact'
                >
                    Get in touch
                </Link>

            </div>
            {/*
            <div className="bg-slate-100 p-10 rounded-md shadow-md text-center">
                <h1 className="text-2xl md:text-6xl text-slate-700">Key clients</h1>
                <p className="text-xl md:text-2xl text-slate-700 pt-5">MRI Software</p>
                <p className="text-xl md:text-2xl text-slate-700 pt-5">RESIC Group</p>
            </div>
            */}
        </div>
    );
}

export default Hero;
