import React from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../components/PageTitle';

function NotFound() {

  return (
    <>
        <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-slate-900 to-slate-600 text-white">

          <PageTitle title="Page not found" />

          <div className="mx-20 py-20">

              <p className="text-left">Unfortunately, the page you were looking for either doesn't exist or has been removed.</p>

              <div className="container mx-auto my-10 text-center">
                  <Link to="/"
                    className="text-white hover:text-gray-900 bg-blue-600 hover:bg-blue-400 border-2 border-blue-400 hover:border-transparent rounded-lg py-2 px-4 mt-4 inline-block"
                  >
                      Go back to the home page
                  </Link>
              </div>

            </div>

        </div>

    </>
  );
}

export default NotFound;
