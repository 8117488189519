import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Field, Label, Switch } from '@headlessui/react'
import PageTitle from '../components/PageTitle';

function Contact() {

  const [agreed, setAgreed] = useState(false);
  const [status, setStatus] = useState('idle'); // 'idle', 'submitting', 'success', 'error'
  const [showError, setShowError] = useState(false);

  const handleSwitchChange = (value) => {
    setAgreed(value);
    if (showError && value) {
      setShowError(false); // Clear the error when checkbox is ticked
    }
  };

  const firstNameField = useRef();
  const lastNameField = useRef();
  const companyField = useRef();
  const emailField = useRef();
  const phoneField = useRef();
  const messageField = useRef();

  const onSubmit = async (event) => {

      event.preventDefault();

      // Check if the user has agreed to the privacy policy
      if (!agreed) {
          setShowError(true);
      } else {
          setShowError(false);
          setStatus('submitting');

          try {
              await fetch('https://rk6k3jwha5ocp5h7y27mmzzm340nzqcn.lambda-url.ap-southeast-2.on.aws/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    first_name: firstNameField.current.value,
                    last_name: lastNameField.current.value,
                    company: companyField.current.value,
                    email: emailField.current.value,
                    phone: phoneField.current.value,
                    message: messageField.current.value,
                }),
              }).then((response) => response.json()).then((data) => {
                  if (data.message_id) {
                      setStatus('success');
                  } else {
                      setStatus('error');
                  }
              });
          } catch (error) {
              setStatus('error');
          };
      }
  };

  return (

    <>

        <link rel="canonical" href="https://www.matterhive.com.au/contact" />

        <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-slate-900 to-slate-600 text-white">

          <PageTitle title="Contact Us" />

<div className="isolate bg-slate-100 my-12 px-6 py-12 sm:py-16 lg:px-8">

      <div className="mx-auto max-w-2xl text-left">
        <p className="mt-2 text-lg/8 text-gray-600">Interested in working with MatterHive Software? Fill out the contact form below with details of your project, and we'll be in touch.</p>
      </div>

      <form onSubmit={onSubmit} action="#" method="POST" className="mx-auto mt-16 max-w-2xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label htmlFor="first-name" className="block text-sm/6 font-semibold text-gray-900">
              First name
            </label>
            <div className="mt-2.5">
              <input
                id="first-name"
                name="first-name"
                type="text"
                required
                autoComplete="given-name"
                className="block w-full rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-slate-600"
                ref={firstNameField}
              />
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm/6 font-semibold text-gray-900">
              Last name
            </label>
            <div className="mt-2.5">
              <input
                id="last-name"
                name="last-name"
                required
                type="text"
                autoComplete="family-name"
                className="block w-full rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-slate-600"
                ref={lastNameField}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="company" className="block text-sm/6 font-semibold text-gray-900">
              Company
            </label>
            <div className="mt-2.5">
              <input
                id="company"
                name="company"
                type="text"
                autoComplete="organization"
                className="block w-full rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-slate-600"
                ref={companyField}
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm/6 font-semibold text-gray-900">
              Email
            </label>
            <div className="mt-2.5">
              <input
                id="email"
                name="email"
                type="email"
                required
                autoComplete="email"
                className="block w-full rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-slate-600"
                ref={emailField}
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm/6 font-semibold text-gray-900">
              Phone Number
            </label>
            <div className="mt-2.5">
              <input
                id="phone-number"
                name="phone-number"
                type="phone-number"
                className="block w-full rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-slate-600"
                ref={phoneField}
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm/6 font-semibold text-gray-900">
              Message
            </label>
            <div className="mt-2.5">
              <textarea
                id="message"
                name="message"
                rows={4}
                required
                className="block w-full rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-slate-600"
                defaultValue={''}
                ref={messageField}
              />
            </div>
          </div>
          <Field className="flex gap-x-4 sm:col-span-2">
            <div className="flex h-6 items-center">
              <Switch
                checked={agreed}
                onChange={handleSwitchChange}
                className="group flex w-8 flex-none cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 data-[checked]:bg-indigo-600"
              >
                <span className="sr-only">Agree to policies</span>
                <span
                  aria-hidden="true"
                  className="size-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
                />
              </Switch>
            </div>
            <Label className="text-sm/6 text-gray-600">
              By selecting this, you agree to our{' '}
              <Link to="/privacy" className="font-semibold text-bold">
                privacy&nbsp;policy
              </Link>
              .
              {showError && (
                <span className="text-red-500 ml-1">You must agree to the privacy policy.</span>
              )}
            </Label>
          </Field>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            disabled={status === 'submitting'}
          >

          {status === 'submitting' &&
              <svg aria-hidden="true" className="inline mr-2 w-5 h-5 text-white animate-spin dark:text-white fill-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
          }

            {status === 'submitting' ? 'Submitting...' : "Let's talk"}
          </button>
        </div>

        {status === 'success' && <p className="mx-auto max-w-2xl text-center text-green-500 mt-8 text-lg/8">Form submitted successfully!</p>}
        {status === 'error' && <p className="mx-auto max-w-2xl text-center text-red-500 mt-8 text-lg/8">There was an error submitting the form. Please try again.</p>}

      </form>
    </div>

        </div>

    </>
  );


}

export default Contact;
