import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {

    const year = new Date().getFullYear();

    return (
        <footer className="bg-black text-white min-h-48 p-16">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4">
                <div className="text-left">
                    <p className="mb-5">Copyright &copy; MatterHive Software Pty Ltd { year }</p>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="main-grid-item-icon inline w-5 mr-2" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                      <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
                      <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
                    </svg>

                    <Link to="/privacy" className="text-white">Privacy Policy</Link>

                </div>
                <div className="text-left">
                    <p className="hidden md:block">MatterHive Software Pty Ltd</p>
                    <p>ACN: 683 229 503</p>
                    <p>ABN: 87 683 229 503</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
