import React from 'react';
import Hero from '../components/Hero';

function Home() {
  return (
    <>
        <link rel="canonical" href="https://www.matterhive.com.au" />
        <Hero />
    </>
  );
}

export default Home;
