import React from 'react';
import { Link } from 'react-router-dom';

function Dropdown({ isOpen, toggle }) {
    return (
        <div className={ isOpen ? "grid grid-rows-4 text-center items-center bg-slate-800 text-white" : "hidden" } onClick={ toggle }>
            <Link to="/" className="p-4 hover:bg-white hover:text-black">
                Home
            </Link>
            <Link to="/services" className="p-4 hover:bg-white hover:text-black">
                Services
            </Link>
            <Link to="/about" className="p-4 hover:bg-white hover:text-black">
                About
            </Link>
            <Link to="/contact" className="p-4 hover:bg-white hover:text-black">
                Contact
            </Link>
        </div>
    );
}

export default Dropdown;
