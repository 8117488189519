import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Dropdown from './components/Dropdown';
import Footer from './components/Footer';
import Home from './pages/home';
import Services from './pages/services';
import About from './pages/about';
import Contact from './pages/contact';
import Privacy from './pages/privacy';
import NotFound from './pages/notfound';

function App() {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
     setIsOpen(!isOpen);
  };

  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', hideMenu);

    return () => {
      window.removeEventListener('resize', hideMenu);
    };
  });

  return (
    <>
        <Navbar toggle={toggle} setIsOpen={setIsOpen} />
        <Dropdown toggle={toggle} isOpen={isOpen} />
        <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path='/' exact element={<Home />} />
            <Route path='/services' element={<Services />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/privacy' element={<Privacy />} />
        </Routes>
        <Footer />
    </>
  );
}

export default App;
