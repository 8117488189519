import React from 'react';

function ServiceBadge({ title, description, openModal }) {
  return (
      <div className="bg-slate-300 rounded-lg text-black text-lg p-5 text-left shadow-md border-2 border-slate-700 cursor-pointer
                      transition ease-in-out hover:-translate-y-1 hover:scale-105 hover:bg-slate-400 duration-200"
           onClick={() => openModal(description)}>
          <p>
              {title}
          </p>
      </div>
  );
}

export default ServiceBadge;
