import React from 'react';

function PageTitle({ title }) {

    return (
          <h1 className="text-center mt-20 md:mt-0 pt-20 text-2xl md:text-7xl uppercase font-black">{title}</h1>
    );

}

export default PageTitle;
