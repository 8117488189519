import React, { useRef, useEffect, useState, useCallback } from 'react';

function ServiceModal({ isOpen, onClose, children }) {

    const [isVisible, setIsVisible] = useState(false); // Controls animation
    const modalRef = useRef();

    const handleClose = useCallback(() => {
        setIsVisible(false);
        setTimeout(() => onClose(), 200); // Wait for animation to finish
    }, [onClose]);

    useEffect(() => {

        // Close the modal if clicked outside of it
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        };

        if (isOpen) {
            setIsVisible(true);
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, [isOpen, setIsVisible, handleClose]);

    if (!isOpen) return null;

    return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-200 ${isVisible ? "opacity-100" : "opacity-0"}`}
    >
      <div
        ref={modalRef}
        className={`bg-white p-6 rounded shadow-lg max-w-md w-full transform transition-transform duration-200 ${isVisible ? "translate-y-0" : "translate-y-4"}`}
      >
            <button
              className="text-black float-right w-8 h-8 absolute top-2 right-4"
              onClick={handleClose}
            >

                <svg data-slot="icon" aria-hidden="true" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>

            </button>

            <div className="mt-4 text-black text-justify whitespace-pre-line">{children}</div>

          </div>
        </div>
    );
}

export default ServiceModal;
