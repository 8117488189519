import React from 'react';
import PageTitle from '../components/PageTitle';

function About() {

  const years = new Date().getFullYear() - 2007;

  return (
    <>

        <link rel="canonical" href="https://www.matterhive.com.au/about" />

        <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-slate-900 to-slate-600 text-white">

          <PageTitle title="About MatterHive Software" />

          <div className="grid md:grid-cols-2 w-full">

              <div className="h-full">

                  <p className="text-lg m-5 md:m-20 text-justify">
                      MatterHive Software is backed by over {years} years of experience in the software development industry. MatterHive's founder, <a href="https://www.linkedin.com/in/mjhealy" className="text-blue-300 hover:text-blue-200">Matt Healy</a>, was a key player in the growth of the Australian real estate software CRM, MyDesktop, between 2007 and 2016. In 2017, Matt co-founded the next generation of real estate software, <a href="https://www.mrisoftware.com/au/products/vault/" className="text-blue-300 hover:text-blue-200">VaultRE</a>, which quickly grew to become the number one real estate CRM in Australia. In 2020, VaultRE become part of the PropTech Group (ASX:PTG). PropTech Group was subsequently <a href="https://www.mrisoftware.com/au/news/mri-software-completes-acquisition-proptech-group-asx-ptg/" className="text-blue-300 hover:text-blue-200">acquired by MRI Software</a> in 2023 at a valuation of around $AUD 93 million. Matt served as Director of Engineering for Enterprise CRM at MRI Software APAC until the end of 2024.
                  </p>
                  {/*
                  <p className="text-lg m-5 md:m-20">
                      timeline in here
                  </p>
                  */}
                  <p className="text-lg m-5 md:m-20 text-justify">
                      MatterHive Software was founded to provide software consultancy services to businesses looking to build or improve their software products, particularly in the PropTech industry. MatterHive Software specialises in building web applications, APIs, and integrations. MatterHive Software is based in Perth, Australia.
                  </p>

              </div>

              <div className="w-full p-20">
                  <img src="/images/matt3.png" alt="Matt Healy" className="rounded-full w-80 sm:w-96 mx-auto" />
                  <p className="text-center text-lg mt-5 italic">Matt Healy - Managing Director</p>
              </div>
          </div>
        </div>

    </>
  );
}

export default About;
